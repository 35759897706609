import { CometChat } from "@cometchat-pro/chat";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { AUTH_KEY } from "../config";
import app from "../config/firebase";

const signInIntoApp = () => {
  return new Promise((resolve, reject) => {
    const provider = new GoogleAuthProvider();

    const auth = getAuth(app);
    signInWithPopup(auth, provider)
      .then((result) => {
        const firebaseUser = result.user;
        console.log(firebaseUser);

        CometChat.login(firebaseUser.uid, AUTH_KEY).then(
          (user) => {
            console.log("Login Successful:", { user });
            resolve(user);
          },
          (_error) => {
            const user = new CometChat.User(firebaseUser.uid);
            user.setName(firebaseUser.displayName || firebaseUser.uid);
            if (firebaseUser.photoURL) {
              user.setAvatar(firebaseUser.photoURL);
            }
            CometChat.createUser(user, AUTH_KEY).then(
              async (_cometChatUser) => {
                console.log("user created", user);
                await CometChat.login(user.getUid(), AUTH_KEY);
                resolve(user);
              },
              (error) => {
                console.log("error", error);
                return error;
              }
            );
          }
        );
      })
      .catch((error) => {
        const errorMessage = error.message;
        alert(`Some error occurred ${errorMessage}`);
        reject(error);
      });
  });
};

export default signInIntoApp;
