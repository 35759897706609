import React, { useEffect, useState } from "react";
import { CometChat } from "@cometchat-pro/chat";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import getRandomString from "../util/getRandomString";
import CssTextField from "../components/CssTextField";
import ColorButton from "../components/ColorButton";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Popper,
  Typography,
} from "@mui/material";
import Logo from "../components/Logo";
import AppBar from "../components/AppBar";
import signInIntoApp from "../util/signInIntoApp";
import { getAuth } from "firebase/auth";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { APP_ID, AUTH_KEY, REGION } from "../config";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function Call() {
  const { id: sessionID } = useParams();
  const navigate = useNavigate();
  const isMeetingURL = /[a-z]{4,4}-[a-z]{4,4}-[a-z]{4,4}/.test(
    sessionID ? sessionID : ""
  );
  const [meetCode, setMeetCode] = useState(isMeetingURL ? sessionID : "");
  const [noOfParticipants, setNoOfParticipants] = useState(-1);
  const [callStatus, setCallStatus] = useState(false);
  const [inputError, setInputError] = useState("");
  const [user, setUser] = React.useState<CometChat.User | null>(null);
  const [isMeetingInfoOpen, setIsMeetingInfoOpen] = React.useState(false);
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (inputError) {
      if (!meetCode) {
        setInputError("Please put meet code or url");
        return;
      }
      const parsedSessionId = meetCode.replace(
        "https://react--calling.pages.dev/",
        ""
      );
      if (!/^[a-z]{4,4}-[a-z]{4,4}-[a-z]{4,4}$/.test(parsedSessionId)) {
        setInputError("Invalid meet code or url");
        return;
      }
      setInputError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetCode]);

  useEffect(() => {
    setInputError("");
  }, [user]);

  useEffect(() => {
    CometChat.getLoggedinUser().then(
      (user) => {
        setUser(user);
        if (!user) {
          // navigate("/");
        } else if (isMeetingURL) {
          const callType = "DIRECT";

          const getParticpantsJoined = () => {
            CometChat.getCallParticipantCount(
              sessionID as string,
              callType
            ).then(
              (count) => {
                console.log("Participants count =", count);
                setNoOfParticipants(count);
                setTimeout(getParticpantsJoined, 6000);
              },
              (error) => {
                console.log("Some error occurred =", error);
              }
            );
          };

          getParticpantsJoined();
        }
      },
      (error) => {
        console.log("Something went wrong", error);
      }
    );
  }, [navigate, sessionID, isMeetingURL]);

  const handleJoinCall = () => {
    if (!validateInput()) {
      return;
    }
    if (!user) {
      signInIntoApp().then((user) => {
        setUser(user as CometChat.User | null);
        joinCall();
      });
    } else {
      joinCall();
    }
  };

  const validateInput = () => {
    if (!meetCode) {
      setInputError("Please put meet code or url");
      return false;
    }
    const parsedSessionId = meetCode.replace(
      "https://react--calling.pages.dev/",
      ""
    );
    if (!/[a-z]{4,4}-[a-z]{4,4}-[a-z]{4,4}/.test(parsedSessionId)) {
      setInputError("Invalid meet code or url");
      return false;
    }
    setInputError("");
    return true;
  };

  const joinCall = () => {
    // setTouched(true);

    // CometChat.getLoggedinUser().then(
    //   (user) => {
    //     if (!user) {
    //       navigate("/");
    //     }
    //   },
    //   (error) => {
    //     console.log("Something went wrong", error);
    //   }
    // );

    const audioOnly: boolean = false;

    const callSettings: CometChat.CallSettings =
      new CometChat.CallSettingsBuilder()
        .enableDefaultLayout(true)
        .startWithAudioMuted(noOfParticipants > 0)
        .setSessionID(meetCode!)
        .setIsAudioOnlyCall(audioOnly)
        .build();

    CometChat.startCall(
      callSettings,
      document.getElementById("callScreen") as HTMLElement,
      new CometChat.OngoingCallListener({
        onUserJoined: (user: CometChat.User) => {
          console.log("user joined:", user);
        },
        onUserLeft: (user: CometChat.User) => {
          console.log("user left:", user);
        },
        onUserListUpdated: (userList: CometChat.User[]) => {
          console.log("user list:", userList);
          if (userList.length === 1) {
            setIsMeetingInfoOpen(true);
          }
        },
        onCallEnded: (call: CometChat.Call) => {
          console.log("Call ended:", call);
          setCallStatus(false);
        },
        onError: (error: CometChat.CometChatException) => {
          console.log("Error :", error);
        },
        onMediaDeviceListUpdated: (deviceList: Object) => {
          console.log("Device List:", deviceList);
        },
        onUserMuted: (
          userMuted: CometChat.User,
          userMutedBy: CometChat.User
        ) => {
          // This event will work in JS SDK v3.0.2-beta1 & later.
          console.log("Listener => onUserMuted:", userMuted, userMutedBy);
        },
        onScreenShareStarted: () => {
          // This event will work in JS SDK v3.0.3 & later.
          console.log("Screen sharing started.");
        },
        onScreenShareStopped: () => {
          // This event will work in JS SDK v3.0.3 & later.
          console.log("Screen sharing stopped.");
        },
      })
    );
    setCallStatus(true);
    initializeChatWidget(meetCode!);
  };

  const initializeChatWidget = (groupId: string) => {
    (window as any).CometChatWidget.init({
      appID: APP_ID,
      appRegion: REGION,
      authKey: AUTH_KEY,
    }).then(
      (_response: any) => {
        const chatElement = document.getElementById("chat-widget");
        if (chatElement) {
          chatElement.innerHTML = "";
        }
        (window as any).CometChatWidget.launch({
          widgetID: "cd9e340c-c07f-4212-80f0-ae099c0d62a4",
          target: "#chat-widget",
          roundedCorners: "false",
          height: "100vh",
          width: "min(100vw, 400px)",
          defaultID: groupId,
          defaultType: "group",
        });
      },
      (error: any) => {
        console.log("Initialization failed with error:", error);
        CometChat.endCall(meetCode!);
      }
    );
  };

  return (
    <React.Fragment>
      <AppBar visible={!callStatus} user={user} setUser={setUser} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        id="callScreen"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          overflow: "hidden",
          left: -1,
          display: callStatus ? "" : "none",
          zIndex: 999999,
        }}
      >
        <div
          id="chat-widget"
          style={{
            transform: `translate(${
              isChatOpen ? "0px" : "min(100vw, 400px)"
            }, 0px)`,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: 8,
            right: isChatOpen
              ? "calc(min(100vw, 400px) - 15px)"
              : "var(--chat-icon-distance)",
            backgroundColor: isChatOpen ? "#fff" : "transparent",
            padding: 10,
            borderRadius: 40,
            transition: ".6s",
          }}
        >
          <IconButton
            onClick={() => setIsChatOpen((prev) => !prev)}
            sx={{ padding: 0 }}
          >
            {isChatOpen ? (
              <ArrowForwardIosIcon style={{ color: "#333" }} />
            ) : (
              <ChatOutlinedIcon style={{ color: "#ffffffa0" }} />
            )}
          </IconButton>
        </div>
        <Popper open={callStatus} style={{ zIndex: 999999, top: 10, left: 10 }}>
          {isMeetingInfoOpen ? (
            <Box
              sx={{
                p: 2,
                bgcolor: "background.paper",
                borderRadius: 2,
                maxWidth: "min(350px, 100vw - 52px)",
              }}
            >
              {/* <span >X</span> */}
              <IconButton
                style={{ position: "absolute", right: 10, top: 10 }}
                size="small"
                onClick={() => setIsMeetingInfoOpen(false)}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="body1"
                style={{ fontSize: 20, marginBottom: 10 }}
              >
                {" "}
                Your meeting info{" "}
              </Typography>
              <Typography
                variant="body1"
                className="text-type1"
                style={{ marginBottom: 10 }}
              >
                {" "}
                Share this joining info with others who you want to join the
                meetings{" "}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontSize: 14, color: "#333" }}
                >
                  {" "}
                  react--calling.pages.dev/{meetCode}{" "}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://react--calling.pages.dev/${meetCode}`
                    );
                  }}
                >
                  <ContentCopyRoundedIcon />
                </IconButton>
              </div>
              {getAuth().currentUser?.email && (
                <Typography
                  variant="body1"
                  style={{ fontSize: 12, color: "#555", marginTop: 10 }}
                >
                  {" "}
                  Joined as {getAuth().currentUser?.email}{" "}
                </Typography>
              )}
            </Box>
          ) : (
            <IconButton onClick={() => setIsMeetingInfoOpen(true)}>
              <InfoOutlinedIcon style={{ color: "#fff" }} />
            </IconButton>
          )}
        </Popper>
      </div>
      <div className="cc">
        <Container maxWidth="sm">
          <Logo
            style={{
              flexGrow: 1,
              alignSelf: "center",
              margin: "60px 0px",
              padding: 20,
            }}
            svgStyle={{ height: "auto", width: "100%" }}
            fill="var(--primary-text)"
          />
          {noOfParticipants === 0 && (
            <h1 style={{ color: "var(--primary-text)" }}>
              No one else is here.
            </h1>
          )}
          {noOfParticipants > 0 && (
            <h1 style={{ color: "var(--primary-text)" }}>
              {noOfParticipants} already in call.
            </h1>
          )}
          <CssTextField
            value={meetCode}
            onChange={(e) => setMeetCode(e.target.value)}
            disabled={isMeetingURL}
            label="Enter code or URL"
            style={{ width: "100%" }}
          />
          {inputError && (
            <Alert style={{ marginTop: 20 }} severity="error" variant="filled">
              {inputError}
            </Alert>
          )}
          <div className="btn-container">
            {isMeetingURL && (
              <ColorButton
                variant="contained"
                style={{ flex: 1, marginTop: 20 }}
                onClick={() => {
                  navigator.clipboard
                    .writeText(`https://react--calling.pages.dev/${sessionID}`)
                    .then(
                      function () {
                        alert("Invitation link copied to clipboard.");
                      },
                      function () {
                        alert("Eroro");
                      }
                    );
                }}
              >
                Copy Invite Link
              </ColorButton>
            )}
            {!isMeetingURL && (
              <ColorButton
                variant="contained"
                style={{ flex: 1, marginTop: 20 }}
                onClick={() => {
                  if (!user) {
                    setInputError("Please login first.");
                    return;
                  }
                  const newMeetId = `${getRandomString()}-${getRandomString()}-${getRandomString()}`;

                  const GUID: string = newMeetId!;
                  const groupName: string = `Meeting Chat - ${newMeetId}`;
                  const groupType: string = CometChat.GROUP_TYPE.PUBLIC;

                  const group: CometChat.Group = new CometChat.Group(
                    GUID,
                    groupName,
                    groupType
                  );
                  group.setIcon(
                    "https://cdn-icons-png.flaticon.com/512/32/32441.png"
                  );

                  setLoading(true);
                  CometChat.createGroup(group).then(
                    (_group: CometChat.Group) => {
                      setLoading(false);
                      setMeetCode(newMeetId);
                      navigate(`/${newMeetId}`, { state: { id: newMeetId } });
                    },
                    (error: CometChat.CometChatException) => {
                      setLoading(false);
                      alert("Group creation failed with exception:");
                      console.log(error);
                    }
                  );
                }}
              >
                Start Instant Meeting
              </ColorButton>
            )}
            <ColorButton
              variant="contained"
              onClick={handleJoinCall}
              style={{ flex: 1, marginTop: 20 }}
            >
              {user ? "" : "Login & "}Join
            </ColorButton>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Call;
