import React from "react";
import { CometChat } from "@cometchat-pro/chat";
import {
  AppBar as AppBarMui,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import Logo from "../components/Logo";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import GoogleSignInButton from "./GoogleSignInButton";
import signInIntoApp from "../util/signInIntoApp";

function AppBar({
  visible = true,
  user,
  setUser,
}: {
  visible: boolean;
  user: CometChat.User | null;
  setUser: React.Dispatch<React.SetStateAction<CometChat.User | null>>;
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    Promise.all([CometChat.logout(), getAuth().signOut()]).then(() => {
      setUser(null);
      navigate("/");
    });
    setAnchorEl(null);
  };

  return (
    <AppBarMui
      position="static"
      style={{
        backgroundColor: "#6929ca",
        display: visible ? "" : "none",
      }}
    >
      <Toolbar>
        <Logo
          style={{ flexGrow: 1 }}
          svgStyle={{ height: 30, width: "auto" }}
        />
        {user ? (
          <div>
            <IconButton size="large" onClick={handleMenu} color="inherit">
              <Avatar
                sx={{
                  bgcolor: "var(--off-white)",
                  color: "var(--primary)",
                  fontWeight: "bold",
                }}
                alt="Remy Sharp"
                src={user?.getAvatar() || undefined}
              >
                {user?.getName()[0]}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        ) : (
          <GoogleSignInButton
            onClick={() => {
              signInIntoApp().then((user) => {
                setUser(user as CometChat.User | null);
              });
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          />
        )}
      </Toolbar>
    </AppBarMui>
  );
}

export default AppBar;
